<template>
    <div>
        <ConfirmationModal ref="confirm"/>

        <v-alert v-if="hasError" type="error">
            {{ $t('errors.generic') }}
        </v-alert>
        <v-data-table
            v-else
            :loading="computedLoading"
            :headers="computedHeaders"
            :items="items"
            v-bind="attrs"
            v-on="listeners"
            disable-pagination
            hide-default-footer
            disable-sort
            class="px-3 pt-2"
            :mobile-breakpoint="0"
        >
            <template #loading>
                {{ $t(`tables.${tableName}.loading`) }}
            </template>

            <template #no-data>
                {{ $t(`tables.${tableName}.no_data`) }}
            </template>

            <template #top>
                <!-- Desktop -->
                <div v-if="$vuetify.breakpoint.smAndUp">
                    <v-toolbar flat>
                        <v-toolbar-title class="primary--text font-weight-bold table-title">{{ $t(`tables.${tableName}.title`) }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="actions"></slot>
                        <slot name="main-btn">
                            <v-btn v-if="!createBtnHidden" @click="createItem" outlined :loading="computedLoading" height="36px" width="86px" class="mr-3 primary--text" >{{ $t('actions.add') }}</v-btn>
                        </slot>
                    </v-toolbar>
                    <slot name="search"></slot>
                    <slot name="filter"></slot>
                </div>

                <!-- Mobile -->
                <div v-else class="pa-4 pb-0">
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex align-center">
                            <span class="primary--text font-weight-bold table-title">{{ $t(`tables.${tableName}.title`) }}</span>
                            <div class="ml-auto">
                                <slot class="" name="actions"></slot>
                            </div>
                        </v-col>
                        <v-col cols="12" class="mt-4">
                            <slot name="filter"></slot>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                            <slot name="main-btn">
                                <v-btn v-if="!createBtnHidden" @click="createItem" block outlined :loading="computedLoading" height="36px" width="86px" class="primary--text" >{{ $t('actions.add') }}</v-btn>
                            </slot>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <template #item="props" v-if="hasSlot('item')">
                <slot name="item" v-bind="props"/>
            </template>

            <template v-for="value in itemSlotsValues" #[value]="props">
                <slot :name="value" :item="props.item" :header="props.header" :value="props.value">
                    {{ props.value }}
                </slot>
            </template>

            <template #item.actions="{ item }">
                <slot name="icons.start" v-bind:item="item"></slot>
                <v-tooltip bottom :disabled="computedShowBtnDisabled">
                    <template v-slot:activator="{ on }" v-if="showDetailItem">
                        <span v-if="$vuetify.breakpoint.smAndUp" v-on="on" @click="showItem(item)" :class="{ pointer: !computedShowBtnDisabled }" class="mr-3 primary--text">{{ $t('tables.BaseCrudTable.details') }}</span>
                        <v-icon
                            v-on="on"
                            v-if="$vuetify.breakpoint.xs"
                            small
                            :disabled="computedShowBtnDisabled"
                            :class="computedShowBtnDisabled ? 'mr-0 disabled-icon' : 'mr-2 primary--text'"
                            @click="showItem(item)"
                        >
                            fas fa-fw fa-plus
                        </v-icon>
                    </template>
                    <span>{{ $t(`tables.${tableName}.tooltips.details`) }}</span>
                </v-tooltip>
                <v-tooltip bottom :disabled="computedEditBtnDisabled || item.allow_update === false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on" icon
                            @click="editItem(item)"
                            :disabled="computedEditBtnDisabled || item.allow_update === false"
                            :class="computedEditBtnDisabled || item.allow_update === false ? 'mr-0 disabled-icon' : 'mr-0'"
                        >
                            <v-icon small color="secondary_font">$cp_edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t(`tables.${tableName}.tooltips.edit`) }}</span>
                </v-tooltip>
                <v-tooltip bottom :disabled="computedDeleteBtnDisabled || item.allow_delete === false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on" icon
                            @click="deleteItem(item)"
                            :disabled="computedDeleteBtnDisabled || item.allow_delete === false"
                            :class="computedDeleteBtnDisabled || item.allow_delete === false ? 'disabled-icon' : ''"
                        >
                            <v-icon small color="secondary_font">$cp_trash</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t(`tables.${tableName}.tooltips.delete`) }}</span>
                </v-tooltip>

                <slot name="icons.end" v-bind:item="item"></slot>
            </template>

            <template #footer>
                <SimplePaginator
                    :pagination.sync="pagination"
                    :items="items"
                    :hidePerPage="false"
                    :perPageDisabled="computedLoading"
                    class="pr-1 pt-6 pb-4"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import SimplePaginator      from '@/shared/components/paginators/SimplePaginator'
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import HasSlot              from '@/mixins/HasSlot'

export default {
    name: 'BaseCrudTable',
    inheritAttrs: false,
    props: {
        tableName: {
            type: String,
            required: true,
        },
        headerConfigs: {
            type: Array,
            required: true,
        },
        fetchFunction: {
            type: Function,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
        showDetailItem: {
            type: Boolean,
            default: true,
            required: false,
        },
        showActions: {
            type: Boolean,
            default: true,
        },
        createBtnHidden: {
            type: Boolean,
            default: false,
        },
        showBtnDisabled: {
            type: Boolean,
            default: false,
        },
        editBtnDisabled: {
            type: Boolean,
            default: false,
        },
        deleteBtnDisabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ HasErrorHandlerMixin, HasSlot ],
    components: { ConfirmationModal, SimplePaginator },
    data: () => ({
        items            : [],
        itemSlotsValues  : [],
        firstHeadersSetup: true,
        loading          : false,
        hasError         : false,
        propLoading      : false,
        additionalParams : {},
    }),
    created() {
        this.setupItemSlotsValues()
    },
    methods: {
        async preFetchItems(additionalParams = {}) {
            this.additionalParams = additionalParams
            await this.fetchItems(this.additionalParams)
                .catch(this.preErrorHandler)
        },
        async fetchItems(additionalParams = {}) {
            this.hasError = false
            this.items    = []
            this.loading  = true
            this.items    = await this.fetchFunction(this.pagination, additionalParams)
                .catch(this.preErrorHandler)
            this.loading = false
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        setupItemSlotsValues() {
            this.headerConfigs.forEach(header => {
                this.itemSlotsValues.push(`item.${header.value}`)
            })
        },
        setupHeaders(showAll) {
            let headers = []
            
            if (this.firstHeadersSetup && this.showActions)
                this.headerConfigs.push({ value: 'actions', class: 'd-none', align: 'end', showOnMobile: true })

            this.headerConfigs.forEach(header => {
                if (showAll || header.showOnMobile) {
                    headers.push({
                        ...header,
                        text: this.$t(`tables.${this.tableName}.${header.value}`),
                    })
                }
            })

            this.firstHeadersSetup = false

            return headers
        },
        createItem() {
            this.$emit('create')
        },
        showItem(item) {
            if (this.computedShowBtnDisabled)
                return
            this.$emit('details', item)
        },
        editItem(item) {
            this.$emit('edit', item)
        },
        async deleteItem(item) {
            let r = await this.$refs.confirm.showConfirmation(this.$t(`tables.${this.tableName}.confirm_delete`))
            if (r)
                this.$emit('delete', item)
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { loading, ...attrs } = this.$attrs
            this.propLoading = loading
            return { ...attrs }
        },

        computedHeaders() {
            return this.$vuetify.breakpoint.mdAndUp ? this.setupHeaders(true) : this.setupHeaders(false)
        },
        computedLoading() {
            let value = this.propLoading || this.loading
            this.$emit('update:loading', value)
            return value
        },
        computedShowBtnDisabled() {
            return this.showBtnDisabled || this.computedLoading
        },
        computedEditBtnDisabled() {
            return this.editBtnDisabled || this.computedLoading
        },
        computedDeleteBtnDisabled() {
            return this.deleteBtnDisabled || this.computedLoading
        },
    },
    watch: {
        'pagination': {
            deep: true,
            immediate: true,
            async handler() {
                await this.preFetchItems(this.additionalParams)
            },
        },
    },
}
</script>

<style lang="scss">
.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:last-child td:not(.v-data-table__mobile-row),
.theme--light.v-data-table thead tr:last-child th {
    border-bottom: solid 1px $table-divider-color;
}

.table-title {
    font-size: 16px;
}

.theme--light.v-data-table thead tr th, .v-data-table__mobile-row__header {
    font-size: 14px;
    color: $secondary-font-color;
    font-weight: normal;
}

tr .v-data-table__mobile-row:last-child {
    justify-content: center;
}

.disabled-icon {
    opacity: 0.5;
}

.v-data-table {
    border-radius: 8px;
}

.v-data-table .v-toolbar__content {
    padding-left: 15px;
    padding-right: 5px;
}

.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>