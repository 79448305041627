var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ConfirmationModal", { ref: "confirm" }),
      _vm.hasError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")
          ])
        : _c(
            "v-data-table",
            _vm._g(
              _vm._b(
                {
                  staticClass: "px-3 pt-2",
                  attrs: {
                    loading: _vm.computedLoading,
                    headers: _vm.computedHeaders,
                    items: _vm.items,
                    "disable-pagination": "",
                    "hide-default-footer": "",
                    "disable-sort": "",
                    "mobile-breakpoint": 0
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "loading",
                        fn: function() {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tables." + _vm.tableName + ".loading")
                                ) +
                                " "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "no-data",
                        fn: function() {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("tables." + _vm.tableName + ".no_data")
                                ) +
                                " "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _vm.$vuetify.breakpoint.smAndUp
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-toolbar",
                                      { attrs: { flat: "" } },
                                      [
                                        _c(
                                          "v-toolbar-title",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold table-title"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "tables." +
                                                    _vm.tableName +
                                                    ".title"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("v-spacer"),
                                        _vm._t("actions"),
                                        _vm._t("main-btn", [
                                          !_vm.createBtnHidden
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-3 primary--text",
                                                  attrs: {
                                                    outlined: "",
                                                    loading:
                                                      _vm.computedLoading,
                                                    height: "36px",
                                                    width: "86px"
                                                  },
                                                  on: { click: _vm.createItem }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("actions.add")
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ],
                                      2
                                    ),
                                    _vm._t("search"),
                                    _vm._t("filter")
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "pa-4 pb-0" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex align-center",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "primary--text font-weight-bold table-title"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "tables." +
                                                        _vm.tableName +
                                                        ".title"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [_vm._t("actions")],
                                              2
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "mt-4",
                                            attrs: { cols: "12" }
                                          },
                                          [_vm._t("filter")],
                                          2
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "mt-2",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _vm._t("main-btn", [
                                              !_vm.createBtnHidden
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      attrs: {
                                                        block: "",
                                                        outlined: "",
                                                        loading:
                                                          _vm.computedLoading,
                                                        height: "36px",
                                                        width: "86px"
                                                      },
                                                      on: {
                                                        click: _vm.createItem
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("actions.add")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        },
                        proxy: true
                      },
                      _vm.hasSlot("item")
                        ? {
                            key: "item",
                            fn: function(props) {
                              return [_vm._t("item", null, null, props)]
                            }
                          }
                        : null,
                      _vm._l(_vm.itemSlotsValues, function(value) {
                        return {
                          key: value,
                          fn: function(props) {
                            return [
                              _vm._t(
                                value,
                                [_vm._v(" " + _vm._s(props.value) + " ")],
                                {
                                  item: props.item,
                                  header: props.header,
                                  value: props.value
                                }
                              )
                            ]
                          }
                        }
                      }),
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._t("icons.start", null, { item: item }),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  disabled: _vm.computedShowBtnDisabled
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.showDetailItem
                                      ? {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mr-3 primary--text",
                                                        class: {
                                                          pointer: !_vm.computedShowBtnDisabled
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.showItem(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "tables.BaseCrudTable.details"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.$vuetify.breakpoint.xs
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        class: _vm.computedShowBtnDisabled
                                                          ? "mr-0 disabled-icon"
                                                          : "mr-2 primary--text",
                                                        attrs: {
                                                          small: "",
                                                          disabled:
                                                            _vm.computedShowBtnDisabled
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.showItem(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " fas fa-fw fa-plus "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "tables." +
                                          _vm.tableName +
                                          ".tooltips.details"
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  disabled:
                                    _vm.computedEditBtnDisabled ||
                                    item.allow_update === false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                class:
                                                  _vm.computedEditBtnDisabled ||
                                                  item.allow_update === false
                                                    ? "mr-0 disabled-icon"
                                                    : "mr-0",
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    _vm.computedEditBtnDisabled ||
                                                    item.allow_update === false
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editItem(item)
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "secondary_font"
                                                  }
                                                },
                                                [_vm._v("$cp_edit")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "tables." +
                                          _vm.tableName +
                                          ".tooltips.edit"
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  disabled:
                                    _vm.computedDeleteBtnDisabled ||
                                    item.allow_delete === false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                class:
                                                  _vm.computedDeleteBtnDisabled ||
                                                  item.allow_delete === false
                                                    ? "disabled-icon"
                                                    : "",
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    _vm.computedDeleteBtnDisabled ||
                                                    item.allow_delete === false
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteItem(item)
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "secondary_font"
                                                  }
                                                },
                                                [_vm._v("$cp_trash")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "tables." +
                                          _vm.tableName +
                                          ".tooltips.delete"
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._t("icons.end", null, { item: item })
                          ]
                        }
                      },
                      {
                        key: "footer",
                        fn: function() {
                          return [
                            _c("SimplePaginator", {
                              staticClass: "pr-1 pt-6 pb-4",
                              attrs: {
                                pagination: _vm.pagination,
                                items: _vm.items,
                                hidePerPage: false,
                                perPageDisabled: _vm.computedLoading
                              },
                              on: {
                                "update:pagination": function($event) {
                                  _vm.pagination = $event
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                "v-data-table",
                _vm.attrs,
                false
              ),
              _vm.listeners
            )
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }